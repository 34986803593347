<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('estimations.estimations') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('estimations.basic_information')" @click="toggleTab('basic_information')">
          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label class="m-0 p-0">{{ $t('estimations.customer') }}<span class="text-danger">*</span></label>
                    <div class="form-group row mb-0">
                      <div class="col-sm-8">

                        <!--                                            <select name="" id="customer_id" v-model="data.customer_id" class="custom-select" :class="validation && validation.customer_id ? 'is-invalid' : ''">-->
                        <!--                                                <option v-for="row in customers" :value="row.id" :key="row.id">{{ row.fullname }}</option>-->
                        <!--                                            </select>-->
                        <multiselect
                            :class="validation && validation.customer_id ? 'is-invalid' : ''"
                            id="customer_id"
                            v-model="customer"
                            :placeholder="$t('estimations.customer')"
                            label="fullname"
                            track-by="id"
                            :options="customers"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getCustomers($event)">
                        </multiselect>
                        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('estimations.customer') }}.</span>
                      </div>
                      <div class="col-sm-4">
                        <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                      </div>
                    </div>
                    <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.customer_id[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('estimations.estimate_code') }}</label>
                    <input type="text" v-model="data.estimate_code" class="form-control" :class="validation && validation.estimate_code ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.estimate_code" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.estimate_code[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('estimations.estimate_date') }}</label>
                    <input type="date" v-model="data.estimate_date" class="form-control" :class="validation && validation.estimate_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.estimate_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.estimate_date[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('estimations.currency') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currency_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('exchange_rate') }}</label>
                    <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.exchange_rate[0] }}
                                </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status') }}</label>
                    <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                      <option value="">{{ $t('all') }}</option>
                      <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-5">
            <div class="col-md-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('estimations.items') }}</h6>
                <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
              </div>
              <div class="bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('items.item_details') }}</th>
                    <th width="300px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('estimations.item') }}</span>
                        <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                          <b-icon icon="plus"></b-icon>
                        </a>
                      </div>
                    </th>
                    <th width="140px">{{ $t('estimations.unit_price') }}</th>
                    <th width="140px">{{ $t('estimations.unit') }}</th>
                    <th width="140px">{{ $t('estimations.qty') }}</th>
                    <th width="140px">{{ $t('estimations.tax') }}</th>
                    <th width="80px">{{ $t('estimations.discount_type') }}</th>
                    <th width="140px">{{ $t('estimations.discount_value') }}</th>
                    <th width="80px">{{ $t('estimations.subtotal') }}</th>
                    <th width="10px"> {{ $t('actions') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('estimations.item')">
                        <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name" selected>{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('estimations.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="items"
                                     :multiple="false"
                                     @input="selectItem(index)"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     :internal-search="false"
                                     @search-change="getItems(row.f_by, $event)">
                        </multiselect>
                      </div>
                      <input v-model="row.description" type="text" class="form-control mt-3">
                      <span v-if="validation && validation[`items_list.${index}.item`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.item`][0] }}
                        </span>
                    </td>
                    <td>
                      <input v-model="row.unit_price" @input="setValue(index)" type="number" class="form-control">
                      <span v-if="validation && validation[`items_list.${index}.unit_price`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.unit_price`][0] }}
                        </span>
                    </td>
                    <td>
                      <multiselect v-model="row.unit"
                                   :placeholder="$t('estimations.unit')"
                                   label="name"
                                   track-by="id"
                                   :options="(row.item && row.item.units) ? row.item.units:[]"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   @input="setValueUnit(index)"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                      <input v-model="row.units_number" v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" @input="setValue(index)" type="number" class="form-control mt-3">

                    </td>
                    <td>
                      <input v-model="row.qty" @input="setValue(index)" :disabled="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" type="number" class="form-control">
                      <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items_list.${index}.qty`][0] }}
                      </span>

                    </td>
                    <td>
                      <multiselect v-model="row.tax"
                                   :placeholder="$t('estimations.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @search-change="getTaxes($event)">
                      </multiselect>
                    </td>
                    <td>
                      <select name="" id="discount_type" v-model="row.discount_type" class="custom-select">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                    </td>
                    <td><input v-model="row.discount_value" type="number" class="form-control"></td>
                    <td>{{ row.subtotal }}</td>
                    <td>
                      <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="7"></td>
                    <th>{{ $t('estimations.estimate_subtotal') }}</th>
                    <th><span>{{ data.estimate_subtotal }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="7"></td>
                    <th>{{ $t('estimations.total_after_discount') }}</th>
                    <th><span>{{ data.estimate_total_discount }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="7"></td>
                    <th>{{ $t('estimations.total_after_shipping') }}</th>
                    <th><span>{{ data.estimate_total_shipping }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="7"></td>
                    <th>{{ $t('estimations.total_tax') }}</th>
                    <th><span>{{ data.estimate_total_taxes }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="7"></td>
                    <th>{{ $t('estimations.total') }}</th>
                    <th><span>{{ data.estimate_total }}</span></th>
                    <th class="border-0"></th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <b-tabs content-class="mt-3" class="nav-custom-link">
              <b-tab :title="$t('discount_and_settlement')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('estimations.discount_type') }}</label>
                      <select name="" id="discount_types" v-model="data.discount_type" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_type[0] }}
                                </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('estimations.discount_value') }}</label>
                      <input type="number" v-model="data.discount_value" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_value[0] }}
                                </span>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('delivery_information')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('estimations.shipping_details') }}</label>
                      <select name="" id="shipping_details" v-model="data.shipping_details" class="custom-select" :class="validation && validation.shipping_details ? 'is-invalid' : ''">
                        <option v-for="row in shipping_details_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                      </select>
                      <span v-if="validation && validation.shipping_details" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_details[0] }}
                            </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('estimations.shipping_fees') }}</label>
                      <input type="number" v-model="data.shipping_fees" @input="changeShippingFees" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('estimations.adjustment') }}</label>
                      <input type="number" v-model="data.adjustment" class="form-control" :class="validation && validation.adjustment ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.adjustment" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.adjustment[0] }}
                            </span>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('notes')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-12 mb-5">
                      <label>{{ $t('estimations.notes') }}</label>
                      <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                      <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>


          </div>
        </b-tab>
        <b-tab :title="$t('estimations.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">
          <div class="card card-custom mt-5">
            <div class="card-body">
              <attachments :sales-estimate-id="idEditing"></attachments>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
          <div class="card card-custom mt-5">
            <div class="card-body">
              <activity-log :inner-key="key" :id="idEditing"></activity-log>
            </div>
          </div>
        </b-tab>
      </b-tabs>


      <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save($event)">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";
import ItemForm from "@/view/content/forms/ItemForm";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-estimations",
  components: {'attachments': Attachments, 'item-form': ItemForm, 'customer-form': CustomerForm, 'activity-log': ActivityLog},
  data() {
    return {
      mainRoute: 'sales/estimations',
      mainRouteDependency: 'base/dependency',
      key: 'SalesEstimate',

      data: {
        estimate_code: null,
        estimate_subtotal: 0,
        estimate_total: 0,
        estimate_total_discount: 0,
        estimate_total_shipping: 0,
        estimate_total_taxes: 0,
        shipping_details: null,
        shipping_fees: null,
        adjustment: null,
        customer_id: null,
        discount_type: null,
        discount_value: null,
        currency_id: null,
        exchange_rate: null,
        status: 1,
        estimate_date: null,
        notes: null,
      },
      idEditing: this.$route.params.id,
      isEditing: false,
      idClone: this.$route.params.clone_id ? this.$route.params.clone_id : null,

      customers: [],
      currencies: [],
      items: [],
      taxes: [],
      units: [],
      shipping_details_list: [],
      status_list: [],
      // shipping_details_list: [
      // {id: 0, name: this.$t('shipping_details.auto')},
      // {id: 1, name: this.$t('shipping_details.dont_show')},
      // {id: 2, name: this.$t('shipping_details.show_main_client_details')},
      // {id: 3, name: this.$t('shipping_details.show_secondary_client_details')},
      // ],
      validation: null,
      tax_default: null,
      unit_default: null,
      customer: null,
      items_list_form: {id: null, item: null, description: null, unit_price: null, qty: null, unit: null, units_number: null, tax: null, discount_type: null, discount_value: null, subtotal: null},
      items_list: [],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      tab_name: 'basic_information',
      repeater_validation: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    items_list: {
      handler(val) {
        if (val.length > 0) {
          this.data.estimate_subtotal = parseFloat(this.$_.sumBy(this.items_list, 'subtotal'));
          let _discount_value = this.$_.sumBy(this.items_list, (row) => {
            if (row.discount_type == 1) {
              return 0;
            } else if (row.discount_type == 2) {
              let _in_subtotal = row.subtotal ? parseFloat(row.subtotal) : 0;
              let _in_discount_value = row.discount_value ? (parseFloat(row.discount_value) / 100) : 0;

              return _in_subtotal * _in_discount_value;
            }
            return row.discount_value ? parseFloat(row.discount_value) : 0;
          });

          // this.data.estimate_total_discount = this.data.estimate_subtotal - _discount_value;
          // this.data.estimate_total_shipping = this.data.estimate_subtotal + (this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0);
          this.data.estimate_total_discount = _discount_value;
          this.data.estimate_total_shipping = this.data.shipping_fees ? this.data.shipping_fees : 0;

          this.data.estimate_total_taxes = this.$_.sumBy(this.items_list, (row) => {
            return row.tax ? parseFloat(row.tax.value_rate) : 0;
          });
          this.data.estimate_total_taxes = this.data.estimate_total_taxes ? parseFloat(this.data.estimate_total_taxes) : 0;
          let _shipping_fees = this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0;
          this.data.estimate_total = (this.data.estimate_subtotal + this.data.estimate_total_taxes + _shipping_fees) - _discount_value;


          this.data.estimate_subtotal = this.data.estimate_subtotal ? this.data.estimate_subtotal.toFixed(2) : 0;
          this.data.estimate_total_discount = this.data.estimate_total_discount ? this.data.estimate_total_discount.toFixed(2) : 0;
          this.data.estimate_total_shipping = this.data.estimate_total_shipping ? this.data.estimate_total_shipping.toFixed(2) : 0;
          this.data.estimate_total_taxes = this.data.estimate_total_taxes ? this.data.estimate_total_taxes.toFixed(2) : 0;
          this.data.estimate_total = this.data.estimate_total ? this.data.estimate_total.toFixed(2) : 0;
        }
      },
      deep: true
    },
    '$route'(val) {
      if (val.params.id) {
        this.idEditing = val.params.id;
        this.getData();
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      } else {
        this.data.customer_id = null;
      }
    },
    'data.customer_id': function (val) {
      if (val) {
        let _cu = this.customers.filter(row => row.id == val);
        if (_cu[0]) {
          _cu = _cu[0]
          this.data.currency_id = _cu.currency_id;
        }
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },

  },
  methods: {

    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;
        if (parseFloat(row.qty) <= 0) {
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
          _validation_status = true;
        }else if(!row.qty && _status){
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('quantity_must_be_greater_than_0');
          _validation_status = true;
        }

        if (parseFloat(row.unit_price) <= 0) {
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
          _validation_status = true;
        }else if(!row.unit_price && _status){
          this.validation[`items_list.${index}.unit_price`] = [];
          this.validation[`items_list.${index}.unit_price`][0] = this.$t('unit_price_must_be_greater_than_0');
          _validation_status = true;
        }
        if(!row.item && _status){
          this.validation[`items_list.${index}.item`] = [];
          this.validation[`items_list.${index}.item`][0] = this.$t('the_item_field_must_be_required');
          _validation_status = true;
        }
        if(!row.unit && _status){
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        // if (parseFloat(row.amount_tax) <= 0 && row.tax_applied.length > 0) {
        //   _validation_status = true;
        // }else if(!row.amount_tax && _status && row.tax_applied.length > 0){
        //   _validation_status = true;
        // }

        // if (parseFloat(row.subtotal_before_discount) <= 0) {
        //   _validation_status = true;
        // }else if(!row.subtotal_before_discount && _status){
        //   _validation_status = true;
        // }


        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },
    refactorValidation(){
      this.validationQTYChecking(false);
    },

    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing && this.idClone == null) {
          this.update();
        } else if (this.idClone && this.isEditing == null) {
          this.create();
        } else {
          this.create();
        }
      }
    },

    create() {
      this.data.status = this.data.status ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        items_list: this.items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'estimations.edit', params: {id: response.data.data.id}});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      this.data.status = this.data.status ? 1 : 0;
      let _id = (this.idEditing ? this.idEditing : (this.idClone ? this.idClone : null));
      ApiService.put(`${this.mainRoute}/${_id}`, {
        items_list: this.items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'estimations.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },

    async getData() {
      let _id = (this.idEditing ? this.idEditing : (this.idClone ? this.idClone : null));
      if (_id) {
        ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {
          this.isEditing = true;
          this.data.estimate_code = response.data.data.estimate_code;
          this.data.estimate_subtotal = response.data.data.estimate_subtotal;
          this.data.estimate_total = response.data.data.estimate_total;
          this.data.estimate_total_discount = response.data.data.estimate_total_discount;
          this.data.estimate_total_shipping = response.data.data.estimate_total_shipping;
          this.data.estimate_total_taxes = response.data.data.estimate_total_taxes;
          this.data.shipping_details = response.data.data.shipping_details;
          this.data.shipping_fees = response.data.data.shipping_fees;
          this.data.adjustment = response.data.data.adjustment;
          this.data.customer_id = response.data.data.customer_id;
          this.data.discount_type = response.data.data.discount_type;
          this.data.discount_value = response.data.data.discount_value;
          this.data.currency_id = response.data.data.currency_id;
          this.data.status = response.data.data.status;
          this.data.estimate_date = response.data.data.estimate_date;
          this.data.notes = response.data.data.notes;
          this.data.exchange_rate = response.data.data.exchange_rate;
          this.customer = response.data.data.customer;

          this.items_list = response.data.data.items_list;

          if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
            this.addItemRowToList();
          }
        });
      }
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=4`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },

    // getUnits() {
    //     ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
    //       this.units = response.data.data;
    //     });
    //
    // },

    async getDefaultTax() {
      await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
        this.tax_default = response.data.data;
      });
    },
    // async getDefaultUnit() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-unit`).then((response) => {
    //     this.unit_default = response.data.data;
    //   });
    // },

    getShippingDetails() {
      ApiService.get(this.mainRouteDependency + "/shipping_details").then((response) => {
        this.shipping_details_list = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'sales_estimations'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    loadOptions() {
    },


    addItemRowToList() {
      this.items_list_form.tax = this.tax_default;
      // this.items_list_form.unit = this.unit_default;
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, description: null, unit_price: null, qty: null, unit: null, units_number: null, tax: this.tax_default, discount_type: null, discount_value: null, subtotal: null};
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },

    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;

      this.items_list[index].unit = null;

      if (this.items_list[index].item && this.items_list[index].item.units && this.items_list[index].item.units.length) {
        this.items_list[index].unit = this.items_list[index].item.units.find(row => row.is_main);
      }
    },
    setValueUnit(index) {
      this.items_list[index].qty = 0;
      this.setValue(index);
    },
    setValue(index) {
      this.zeroForNegative();

      if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
          if (this.items_list[index].item.is_min) {
            this.items_list[index].qty = ((this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 0) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0)).toFixed(3);
          } else {
            this.items_list[index].qty = ((this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0) / (this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 0)).toFixed(3);
          }
        } else {
          this.items_list[index].units_number = 0;
        }
      }


      this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
    },

    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if (String(row.unit_price).length > 12) {
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }
        if(String(row.discount_value).length > 9){
          row.discount_value = String(row.discount_value).slice(0, 9);
        }

        if (isNaN(row.unit_price)) {
          row.unit_price = 0;
        } else if (parseFloat(row.unit_price) < 0) {
          row.unit_price = 0;
        }
        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        if ((row.discount_type == 1 || !row.discount_type) && (row.discount_value != 0 && row.discount_value != null)) {
          row.discount_type = 2;
        }
        if (isNaN(row.discount_value)) {
          row.discount_value = 0;
        }else if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }

        if (row.discount_type == 2) {
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        } else if (row.discount_type == 3) {
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }

        return row;

      });
    },

    changeShippingFees: _.debounce(function (){
      let _items_list = [...this.items_list];
      this.items_list = [];
      this.items_list = [..._items_list];
    },100),
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/14").then((response) => {
        this.data.estimate_code = response.data.data.code;
      });
    },

    getDefaultCurrency() {
      ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },

    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

        // this.unit_default = response.data.data.unit;
        this.tax_default = response.data.data.tax;
        // this.items_list_form.unit = response.data.data.unit;
        this.items_list_form.tax = response.data.data.tax;
        if (this.items_list[0]) {
          // this.items_list[0].unit = response.data.data.unit;
          this.items_list[0].tax = response.data.data.tax;
        }
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.estimations_management"), route: '/sales/estimations'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getCurrencies();
    this.getItems();
    this.getShippingDetails();
    this.getStatus();
    // this.getUnits();

    if (this.idClone) {
      this.getData();
    }

    if (this.idEditing) {
      this.getData();
    } else {

      // let promise = this.getDefaultTax();
      // let promise2 = this.getDefaultUnit();
      // Promise.all([promise,promise2]).then(() => {
      //     if (this.items_list.length <= 0) {
      //         this.addItemRowToList();
      //     }
      // });

      this.data.estimate_date = new Date().toISOString().slice(0, 10);
      this.getCode();
    }
    if (!this.idClone && !this.idEditing) {
      this.data.estimate_date = new Date().toISOString().slice(0, 10);
      this.defaultDataForUser();
      //   this.getDefaultCurrency();
    }
    if (this.items_list.length <= 0) {
        this.addItemRowToList();
    }
  },
};
</script>